import React from 'react'
import Form from '../../Form/Form'
import './ModalForm.css'
function ModalForm() {
  return (
    <>
    
   
<button type="button" class="btn  services-quote-btn account-management-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Get A Quote
</button>


<div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-container ">
      <div class="modal-header">
        <h5 class="modal-title modal-heading" id="exampleModalLabel">Fill this form we will contact you soon!</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
    <Form/>

      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default ModalForm