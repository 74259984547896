import React from 'react'
import './Services.css'
import Card from './Card'
import CardData from './CardData'
function Services() {
  return (
    <>
    <div className='container '>
    <h1 className='text-center mt-5'>Services We Offer</h1>
        <div className='row mb-5 mx-auto'>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card 
              imgUrl={CardData[0].imgSrc}
              title={CardData[0].title}
              text={CardData[0].text}
              alt={CardData[0].title}
              link={CardData[0].url}
              

               /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[1].imgSrc}
              title={CardData[1].title}
              text={CardData[1].text}
              alt={CardData[1].title}
              link={CardData[1].url}
              /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[2].imgSrc}
              title={CardData[2].title}
              text={CardData[2].text}
              alt={CardData[2].title}
              link={CardData[2].url}


              /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[3].imgSrc}
              title={CardData[3].title}
              text={CardData[3].text}
              alt={CardData[3].title}
              link={CardData[3].url}


              /> 
            </div>
        </div>
        
        

        <div className='row mb-5 mx-auto'>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card 
              imgUrl={CardData[4].imgSrc}
              title={CardData[4].title}
              text={CardData[4].text}
              alt={CardData[4].title}
              link={CardData[4].url}



               /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[5].imgSrc}
              title={CardData[5].title}
              text={CardData[5].text}
              alt={CardData[5].title}
              link={CardData[5].url}


              /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[6].imgSrc}
              title={CardData[6].title}
              text={CardData[6].text}
              alt={CardData[6].title}
              link={CardData[6].url}


              /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[7].imgSrc}
              title={CardData[7].title}
              text={CardData[7].text}
              alt={CardData[7].title}
              link={CardData[7].url}


              /> 
            </div>
        </div>


        <div className='row mb-5 mx-auto'>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card 
              imgUrl={CardData[8].imgSrc}
              title={CardData[8].title}
              text={CardData[8].text}
              alt={CardData[8].title}
              link={CardData[8].url}



               /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[9].imgSrc}
              title={CardData[9].title}
              text={CardData[9].text}
              alt={CardData[9].title}
              link={CardData[9].url}


              /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[10].imgSrc}
              title={CardData[10].title}
              text={CardData[10].text}
              alt={CardData[10].title}
              link={CardData[10].url}


              /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[11].imgSrc}
              title={CardData[11].title}
              text={CardData[11].text}
              alt={CardData[11].title}
              link={CardData[11].url}


              /> 
            </div>
        </div>

        <div className='row mb-5 mx-auto'>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card 
              imgUrl={CardData[12].imgSrc}
              title={CardData[12].title}
              text={CardData[12].text}
              alt={CardData[12].title}
              link={CardData[12].url}



               /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[13].imgSrc}
              title={CardData[13].title}
              text={CardData[13].text}
              alt={CardData[13].title}
              link={CardData[13].url}


              /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[14].imgSrc}
              title={CardData[14].title}
              text={CardData[14].text}
              alt={CardData[14].title}
              link={CardData[14].url}


              /> 
            </div>
            <div className='col-12 col-sm-6 col-md-3 m-auto'>
              <Card
                 imgUrl={CardData[15].imgSrc}
              title={CardData[15].title}
              text={CardData[15].text}
              alt={CardData[15].title}
              link={CardData[15].url}


              /> 
            </div>
        </div>

    </div>

    </>
  )
}

export default Services