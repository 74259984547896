import React from 'react'
import ArticleContent from './ArticleContent'
function UdaanCatalogue() {
  return (
    <>
<ArticleContent 
 headerName="Udaan Catalogue" 
 serviceName=" Udaan "
imgUrl="/Images/Article/udaan/udaan.png"
/>
    </>
  )
}

export default UdaanCatalogue