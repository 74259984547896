import React from 'react'
import ArticleContent from './ArticleContent'
function SnapdealCatalogue() {
  return (
    <>
<ArticleContent 
 headerName="Snapdeal Catalogue" 
 serviceName=" Snapdeal "
imgUrl="/Images/Article/snapdeal/snapdeal.png"
/>
    </>
  )
}

export default SnapdealCatalogue