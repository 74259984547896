import React from 'react'
import './Services.css'
import Navbar from '../Navbar'
import Callbutton from '../Callbutton'
import ArticleHeader from './ArticleHeader'
import MainContent from './CatalogueTemplate/MainContent'
import Whatsappbutton from '../WhatsappAction/Whatsappbutton'
import Review from './CatalogueTemplate/Review'
import Faq from './PageFaq/Faq'
function AmazonCatalogue() {
  return (
    <>
<Navbar/>
       <div className='container'>
          <ArticleHeader serviceName="Amazon Catalogue"/>
           <div className='row article-container'>
           <h2 className='text-center'> Amazon  <mark>Product Listing</mark> and Cataloging</h2>
           <MainContent service="amazon" />
             <div className='article text-justify'>
             <strong>Technovita Solution</strong> is one of the biggest Partner Service of Amazon . Amazon facilitates over 80 million+ products across the range of over 80 categories.
‍             With the customers getting increasingly reliant on online shopping and the convenience it provides, ecommerce sellers have a promising scope for growth with Amazon.
             </div>
             <div className='article'>&nbsp;</div>
             <div className="article text-justify">
              <Whatsappbutton />
            </div>
             <div className='article text-justify'>
             What we <strong>Technovita Solution </strong>, will do:
            If you want catalogue service for Amazon Business Technovita Solution is the best counter. We are the group of experienced and professionals people. Technovita Solution holds the illustration of its customer presence on Amazon as follows:         
             </div>
             <div className='article'>&nbsp;</div>
             <h3 className='text-center'>How to do a Listing on Amazon:</h3>
           <img className='article-catalogue-service-img  mx-auto' src='/Images/Article/amazon/amazon.png'/>

             <div className='article text-justify'>
              <h5> <strong>1.Catalogue Service for Amazon India -</strong></h5>
               "We handle the complete catalogue service for Amazon India (or other country specific amazon sites). This includes creating database of photographs of products and linking them with appropriate content written by our expert content writers to convert visitors to buyers while giving our maximum information in the most concise way."
             </div>
             <div className='article'>&nbsp;</div>
             <div className='article text-justify'>
              <h5> <strong>2.Account Creation -</strong></h5>
              We create the account of client at the Preferred marketplace to proceed forward and also take the brand approval <strong>GTIN </strong>to make your product online.        
            </div>
            <div className='article'>&nbsp;</div>
            <div className='article text-justify'>
              <h5> <strong>3.Product Listing -</strong></h5>
Our team do listing of products by upload images and text to Amazon template in full compliance with Amazon.           
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>4.Promotion -</strong></h5>
Account launch and the promotion of uploaded products is done by us through the process of smo and seo.
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>5.Daily Updation -</strong></h5>
We update and refresh contents on a daily, weekly or monthly basis as may be agreed upon.
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>6.Amazon Boost Services  -</strong></h5>
We offer premium Amazon boost services that help drive traffic and improve conversions on your Amazon product pages. Since this is an open competitive marketplace we keep tabs on prices and help you make the best offers
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>7.Product Portfolios Creation -</strong></h5>
We create customised product portfolios from which products can be launched or (when stocks are exhausted) removed, enabling full, up to the minute control and accurate information for buyers.
         </div>
         <div className='article'>&nbsp;</div>
         <div className="article text-justify">
              <Whatsappbutton />
            </div>
         <Review accServiceName="amazon"/>

         <div className='article text-justify'>
         <Faq service="amazon" />
<strong>
     “Technovita Solution” helps in Enriching your Product Catalog and also helps increase your product visibility in the online E-Commerce Business."
</strong>
         </div>
           </div>
       </div>
       <Callbutton/>
    </>
  )
}

export default AmazonCatalogue