import React from 'react'
import ContentWritingTemplate from './ContentWritingTemplate'
function NykaaContentWriting() {
  return (
    <>  <ContentWritingTemplate 
    ContentWritingService="Nykaa Content Writing Services – High quality & SEO  friendly"
    contentWritingServiceName="Nykaa"
    /></>
  )
}

export default NykaaContentWriting