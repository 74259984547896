import React from 'react';

function Divider() {
  return < >
      <hr className='solid'/>

  </>;
}

export default Divider;
