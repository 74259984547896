import React from 'react'
import AccountTemplate from './AccountTemplate'

function AmazonAccountManagement() {
  return (
    <>

        <AccountTemplate
        accountManagementService="Amazon Account Management Services" 
        accServiceName="AMAZON"
        imgUrl="/Images/AccManagement/amazonaccount.png"
        imgAlt="amazon Account Management service"
        />
    </>
  )
}

export default AmazonAccountManagement