import React from 'react'
import Uppernav from '../Uppernav'
import Navbar from '../Navbar'
import Slider from '../Slider/Slider'
import Header from '../Header'
import Divider from '../Divider'
import Counter from '../Counter'
import Partner from '../Partner/Partner'
import Contact from '../ContactForm'
import Footer from '../Footer/Footer'
import Callbutton from '../Callbutton'
import Services from '../CatalogueServices/Services'
import Otherservices from '../OtherServices/Otherservices'
import Testimonial from '../Testimonial/Testimonial'
function Home() {
  return (
    <>
       <Uppernav/>
   <Navbar />
   <Slider />
   <Header /> 
   <Divider />
   <Services/>
   <Divider />
   <Otherservices/>
   <Divider />
  <Counter />
  <Divider />
  <Testimonial/>
  <Divider />
  <Partner />
  <Divider />
  <Contact />
   <Footer/>
  <Callbutton/>
    </>
  )
}

export default Home