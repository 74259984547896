import React from 'react';
import './footer.css'

function Footer() {
  return <>

<footer>
    <div class="content">
      <div class="top">
        <div class="logo-details">
        <img style={{width:"150px",height:"100px"}} src="/Images/logo2.png"/>         
        </div>
        <div class="media-icons">
          <a href="https://www.facebook.com/Technovita-Solution-103803212237662/"><i class="fab fa-facebook-f"></i></a>
          <a href="https://mobile.twitter.com/TechnovitaSolu1"><i class="fab fa-twitter"></i></a>
          <a href="https://www.instagram.com/technovitasolution/?hl=en"><i class="fab fa-instagram"></i></a>
          <a href="#"><i class="fab fa-linkedin-in"></i></a>
          <a href="#"><i class="fab fa-youtube"></i></a>
        </div>
      </div>
      
      <div class="link-boxes">
        <ul class="box">
          <li class="link_name">Amazon Services In India</li>
          <li><a href="#">  Amazon Account Management Services</a></li>
          <li><a href="#"> Amazon Listing And Cataloging Services</a></li>
          <li><a href="#">  Amazon Sales Boost Services</a></li>
          <li><a href="#"> Amazon FBA Services</a></li>
          <li><a href="#"> How To Sell On Amazon In India</a></li>
          <li><a href="#"> Amazon Seller Registration</a></li>

        </ul>
        <ul class="box">
          <li class="link_name">Flipkart Services In India</li>
          <li><a href="#">Flipkart Account Management Services</a></li>
          <li><a href="#">Flipkart Listing And Cataloging services</a></li>
          <li><a href="#">Flipkart Sales Boost Services</a></li>
          <li><a href="#">How To Sell On Flipkart In India</a></li>
          <li><a href="#">Flipkart Seller Registration</a></li>

        </ul>
        <ul class="box">
          <li class="link_name">Meesho Services In India</li>
          <li><a href="#">Meesho Account Management Services</a></li>
          <li><a href="#">Meesho Listing And Cataloging Services</a></li>
          <li><a href="#">Meesho Sales Boost Services</a></li>
          <li><a href="#">Meesho Seller Registration</a></li>
          <li><a href="#">How To Sell On Meesho In India</a></li>

        </ul>
        <ul class="box">
          <li class="link_name">Myntra Services In India</li>
          <li><a href="#">Myntra Account Management Services</a></li>
          <li><a href="#">Myntra Listing And Cataloging Services</a></li>
          <li><a href="#">Myntra Sales Boost Services</a></li>
          <li><a href="#">Myntra Seller Registration</a></li>
          <li><a href="#">How To Sell On Myntra In India</a></li>

        </ul>
        </div>

        <div class="link-boxes">
        <ul class="box">
          <li class="link_name">NYKAA Services In India</li>
          <li><a href="#">NYKAA Account Management Services</a></li>
          <li><a href="#">NYKAA Listing And Cataloging Services</a></li>
          <li><a href="#">NYKAA Sales Boost Services</a></li>
          <li><a href="#">NYKAA Seller Registration</a></li>
          <li><a href="#">How To Sell On NYKAA In India</a></li>

        </ul>
        <ul class="box">
          <li class="link_name">Udaan Services In India</li>
          <li><a href="#">Udaan Account Management Services</a></li>
          <li><a href="#">Udaan Listing And Cataloging Services</a></li>
          <li><a href="#">Udaan Sales Boost Services</a></li>
          <li><a href="#">Udaan Seller Registration</a></li>
          <li><a href="#">How To Sell On Udaan In India</a></li>

        </ul>
        <ul class="box">
          <li class="link_name">Paytm Services In India</li>
          <li><a href="#">Paytm Account Management Services</a></li>
          <li><a href="#">Paytm Listing And Cataloging Services</a></li>
          <li><a href="#">Paytm Sales Boost Services</a></li>
          <li><a href="#">Paytm Seller Registration</a></li>
          <li><a href="#">How To Sell On Paytm In India</a></li>

        </ul>
        <ul class="box">
          <li class="link_name">Etsy Services In India</li>
          <li><a href="#"> Etsy Account Management Services</a></li>
          <li><a href="#">Etsy Listing And Cataloging Services</a></li>
          <li><a href="#">Etsy Sales Boost Services</a></li>
          <li><a href="#">Etsy Seller Registration</a></li>
          <li><a href="#">How To Sell On Etsy In India</a></li>

        </ul>
       
        
      </div>



      <div class="link-boxes">
        <ul class="box">
          <li class="link_name">Website Development Services In India</li>
          <li><a href="#">Website Management Services</a></li>
          <li><a href="#">Website creation Services</a></li>
          <li><a href="#">Website Designing Services</a></li>
          

        </ul>
        <ul class="box">
          <li class="link_name">Seo & DigitalMarketing Services In India</li>
          <li><a href="/">Website S.E.O Services</a></li>
          <li><a href="/">Facebook Digital Marketing</a></li>
          <li><a href="/">Ecommerce Digital Marketing</a></li>
         

        </ul>
        <ul class="box">
          <li class="link_name">Customer Support</li>
          <li> <a href="tel:+917451073504"> Call-us +917451073504</a></li>
          <li><a href="mailto:info@technovitasolution.com">info@technovitasolution.com</a></li>
          <li><a href="">Location-D-41, C Block, Sector 59, Noida, Uttar Pradesh 201301</a></li>
        </ul>
        <ul class="box">
          <li class="link_name">Other Important Links</li>
          <li><a href="#"> Privacy policy</a></li>
          <li><a href="#">About-Us</a></li>
          <li><a href="#">Careers</a></li>
          

        </ul>
       
        
      </div>

     
    </div>

    <div class="bottom-details">
      <div class="bottom_text">
        <span class="copyright_text">Copyright © 2022 <a href="/">Technovita Solution</a>All rights reserved</span>
        <span class="policy_terms">
          <a href="/privacy-policy">Privacy policy</a>
          <a href="#">Terms & condition</a>
        </span>
      </div>
    </div>
  </footer>
    
  </>;
}

export default Footer;

