import React from 'react';
import Home from './Components/Pages/Home';
import About from './Components/Pages/About'
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import AmazonCatalogue from './Components/ServicesPagesContent/AmazonCatalogue';
import FlipkartCatalogue from './Components/ServicesPagesContent/FlipkartCatalogue';
import MeeshoCatalogue from './Components/ServicesPagesContent/MeeshoCatalogue';
import PaytmCatalogue from './Components/ServicesPagesContent/PaytmCatalogue';
import EbayCatalogue from './Components/ServicesPagesContent/EbayCatalogue';
import MyntraCatalogue from './Components/ServicesPagesContent/MyntraCatalogue';
import NykaaCatalogue from './Components/ServicesPagesContent/NykaaCatalogue';
import ShopeeCatalogue from './Components/ServicesPagesContent/ShopeeCatalogue';
import AjioCatalogue from './Components/ServicesPagesContent/AjioCatalogue';
import SnapdealCatalogue from './Components/ServicesPagesContent/SnapdealCatalogue';
import EtsyCatalogue from './Components/ServicesPagesContent/EtsyCatalogue';
import UdaanCatalogue from './Components/ServicesPagesContent/UdaanCatalogue';
import LimeroadCatalogue from './Components/ServicesPagesContent/LimeroadCatalogue';
import ShopcluesCatalogue from './Components/ServicesPagesContent/ShopcluesCatalogue';
import JabongCatalogue from './Components/ServicesPagesContent/JabongCatalogues';
import VoonikCatalogue from './Components/ServicesPagesContent/VoonikCatalogue';
import AjioAccountManagement from './Components/ServicesPagesContent/Management/AjioAccountManagement';
import EbayAccountManagement from './Components/ServicesPagesContent/Management/EbayAccountManagement';
import MyntraAccountManagement from './Components/ServicesPagesContent/Management/MyntraAccountManagement';
import NykaaAccountManagement from './Components/ServicesPagesContent/Management/NykaaAccountManagement';
import PaytmAccountManagement from './Components/ServicesPagesContent/Management/PaytmAccountManagement';
import ShopeeAccountManagement from './Components/ServicesPagesContent/Management/ShopeeAccountManagement';
import AmazonAccountManagement from './Components/ServicesPagesContent/Management/AmazonAccountManagement';
import FlipkartAccountManagement from './Components/ServicesPagesContent/Management/FlipkartAccountManagement';
import MeeshoAccountManagement from './Components/ServicesPagesContent/Management/MeeshoAccountManagement';
import FlipkartContentWriting from './Components/ServicesPagesContent/ContentWriting/FlipkartContentWriting';
import AmazonContentWriting from './Components/ServicesPagesContent/ContentWriting/AmazonContentWriting';
import MeeshoContentWriting from './Components/ServicesPagesContent/ContentWriting/MeeshoContentWriting';
import MyntraContentWriting from './Components/ServicesPagesContent/ContentWriting/MyntraContentWriting';
import NykaaContentWriting from './Components/ServicesPagesContent/ContentWriting/NykaaContentWriting';
import Policy from './Components/ServicesPagesContent/Policy';
import AjioOnboarding from './Components/ServicesPagesContent/OnboardingServices/AjioOnboarding';
import MyntraOnboarding from './Components/ServicesPagesContent/OnboardingServices/MyntraOnboarding';
import NykaaOnboarding from './Components/ServicesPagesContent/OnboardingServices/NykaaOnboarding';
function App() {
  return <Router>
  <Routes>
    <Route  path='/' element={ <Home/>}/>
    <Route  path='/about-us' element={<About/>}/>
    <Route path='/services/amazon-catalogue-services' element={<AmazonCatalogue/>}/>
    <Route path='/Flipkart-Catalogue' element={<FlipkartCatalogue/>}/>
    <Route path='/services/Meesho-Catalogue' element={<MeeshoCatalogue/>}/>
    <Route path='/services/paytm-Catalogue' element={<PaytmCatalogue/>}/>
    <Route path='/services/ebay-Catalogue' element={<EbayCatalogue/>}/>
    <Route path='/services/myntra-Catalogue' element={<MyntraCatalogue/>}/>
    <Route path='/services/nykaa-Catalogue' element={<NykaaCatalogue/>}/>
    <Route path='/services/shopee-Catalogue' element={<ShopeeCatalogue/>}/>
    <Route path='/services/ajio-Catalogue' element={<AjioCatalogue/>}/>
    <Route path='/services/snapdeal-Catalogue' element={<SnapdealCatalogue/>}/>
    <Route path='/services/etsy-Catalogue' element={<EtsyCatalogue/>}/>
    <Route path='/services/udaan-Catalogue' element={<UdaanCatalogue/>}/>
    <Route path='/services/limeroad-Catalogue' element={<LimeroadCatalogue/>}/>
    <Route path='/services/shopclues-Catalogue' element={<ShopcluesCatalogue/>}/>
    <Route path='/services/jabong-Catalogue' element={<JabongCatalogue/>}/>
    <Route path='/services/voonik-Catalogue' element={<VoonikCatalogue/>}/>


    <Route path='/ajio-account-management' element={<AjioAccountManagement/>}/>
    <Route path='/ebay-account-management' element={<EbayAccountManagement/>}/>
    <Route path='/myntra-account-management' element={<MyntraAccountManagement/>}/>
    <Route path='/nykaa-account-management' element={<NykaaAccountManagement/>}/>
    <Route path='/paytm-account-management' element={<PaytmAccountManagement/>}/>
    <Route path='/shopee-account-management' element={<ShopeeAccountManagement/>}/>
    <Route path='/amazon-account-management' element={<AmazonAccountManagement/>}/>
    <Route path='/flipkart-account-management' element={<FlipkartAccountManagement/>}/>
    <Route path='/meesho-account-management' element={<MeeshoAccountManagement/>}/>




    <Route path='/how-to-get-onboarded-on-ajio' element={<AjioOnboarding/>}/>
    <Route path='/how-to-get-onboarded-on-myntra' element={<MyntraOnboarding/>}/>
    <Route path='/how-to-get-onboarded-on-nykaa' element={<NykaaOnboarding/>}/>


    <Route path='/flipkart-content-writing' element={<FlipkartContentWriting/>}/>
    <Route path='/amazon-content-writing' element={<AmazonContentWriting/>}/>
    <Route path='/meesho-content-writing' element={<MeeshoContentWriting/>}/>
    <Route path='/myntra-content-writing' element={<MyntraContentWriting/>}/>
    <Route path='/nykaa-content-writing' element={<NykaaContentWriting/>}/>



    <Route path='/privacy-policy' element={<Policy/>}/>

























    






  </Routes>
  </Router>;
}

export default App;
