import React from 'react';
import CountUp from 'react-countup';
import '../index.css'
function Counter() {
  function reset(){

  }
  return <>
      
      <div class="container counter-container ">

    <div class="row">
    <h1 className='steps-headline'>Numbers Speak</h1>
      <div class="feature-box col-lg-4 counter  ">
      <img className='img' src='/Images/client.svg'/>
        <h3 class="feature-title"><CountUp start={0} end={160}  duration={50}/></h3>
        <p>CLIENTS</p>
      </div>

      <div class="feature-box col-lg-4 counter  ">
      <img className='img' src='/Images/upload.svg'/>
        <h3 class="feature-title"><CountUp start={0} end={8000} duration={100} /></h3>
        <p>DAILY ORDER PROCESSED
</p>
      </div>

      <div class="feature-box col-lg-4 counter ">
      <img className='img' src='/Images/growth.svg'/>
        <h3 class="feature-title"><CountUp start={0} end={160526} duration={100}  /></h3>
        <p>DAILY REVENUE GENERATED</p>
      </div>
    </div>


  </div>
  </>;
}

export default Counter;



