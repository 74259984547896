import React from 'react'
import AccountTemplate from './AccountTemplate'

function EbayAccountManagement() {
  return (
    <>
        <AccountTemplate
              accountManagementService="Ebay Account Management Services" 
        accServiceName="Ebay"
        imgUrl="/Images/AccManagement/ebayaccount.png"
        imgAlt="ebay Account Management service"
        />
    </>
  )
}

export default EbayAccountManagement