import React from 'react'
import AboutUs from '../About-us/AboutUs'
function About() {
  return (
    <>
    <AboutUs/>
    </>
  )
}

export default About