import React from 'react'

function OnboardingFaq(props) {
  return (
    <>
<div className="container mt-5 mb-5">
        <h1 className="text-center" style={{color:'#F2903D'}}>{props.onboardingService} Onboarding FAQ-</h1>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                what is {props.onboardingService} Onboarding Service?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
              {props.onboardingService} onboarding service is an process to become a seller on {props.onboardingService} from creating an seller account to listing your products and becoming an succesful {props.onboardingService} seller.
              If you are new to selling on {props.onboardingService} and need any kind of help then we technovita solution can help you to be a successful seller on {props.onboardingService}.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How to do onboarding on {props.onboardingService} ?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                It's could be a bit difficult if you don’t have any technical
                knowledge you can read our article how to do onboarding on 
                {props.onboardingService} and then also you don't understand then we are
                having team of experts who can help you to create you seller account on 
                {props.onboardingService} and help you to start your selling journey on{" "}
                {props.onboardingService} throught your seller journey we will assist you whenever you will need help.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How to create an {props.service} seller account?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
               If you want to create an seller account on {props.onboardingService} then first step is to go on {props.onboardingService} seller portal register yourself their this process can be a little long if you need any kind of help from us then simply you can contact us by call or whatsapp we are always here to help you in best possible way and will make you a successful seller on {props.onboardingService}.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How to increase your chance to become  an successful seller on {props.onboardingService}?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                if you are new seller on {props.onboardingService} then we will highly
                recommend you to make your product listing as good as much it
                can be because this thing only will help you to make your
                products visible infront of millions of people on {" "}
                {props.onboardingService} and will make your product standout form other
                products so for best seo ready product listing or cataloging you
                can reach us on technovitasolution.com or contact us through
                call we will help you to make a successful seller on{" "}
                {props.onboardingService} and will help you to list your products in best
                possible way on {props.onboardingService}.
              </div>
            </div>
          </div>
        </div>
      </div>    
    </>
  )
}

export default OnboardingFaq