import React from 'react'
import './Services.css'
function Card(props) {
  return (
    <>


<div  className='card shadow  '>
                    <img className='card-img-top' src={props.imgUrl} alt={props.alt}/>
                    <hr className='mx-auto w-100' />

                    <div className='card-body'>
                        <h3 className='card-title '>{props.title}</h3> 
                       <p className='card-body-text'>{props.text}</p>
                      <a className='card-btn' href={props.link}>Explore</a>
                    </div>
                    
                </div>

    </>
  )
}

export default Card