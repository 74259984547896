import React from 'react'
import '../Services.css'
function Review(props) {
  return (
    <div>
        <div className="article text-justify">
             {" "}
            <h2 className="text-center">
              <strong>Reviews</strong> ⭐️(4.5/5 based on 50+ Ratings)
            </h2>
          </div>
          <div className="article text-justify">
              <h4>Shiv Kumar ⭐️⭐️⭐️⭐️</h4>
            <p>
              Great Service! I take their advertisment of products on{" "}
              {props.accServiceName} services Best thing is they were on time
              provided best services.
            </p>
          </div>
          <div className="article text-justify">
              <h4>Mukesh kumar ⭐️⭐️⭐️⭐️⭐️</h4>
            <p>
              I took their {props.accServiceName} account mangement services ,
              they are very professional in their work . I will highly recommend
              them you to choose.
            </p>
          </div>
          <div className="article text-justify">
              <h4>harshit patel ⭐️⭐️⭐️⭐️</h4>
            <p>
              {" "}
              I am a seller on {props.accServiceName} intially my sells was not
              that much but after taking their account mangement services my
              sales increase by 400%(4x) they added good keywords on my{" "}
              {props.accServiceName} seller account regarding my products Thanks
              Technovia solution.
            </p>
          </div>
    </div>
  )
}

export default Review