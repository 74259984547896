import React from 'react'
import './Services.css'
import Navbar from '../Navbar'
import Callbutton from '../Callbutton'
import ArticleHeader from './ArticleHeader'
import MainContent from './CatalogueTemplate/MainContent'
import Faq from './PageFaq/Faq'
import Whatsappbutton from '../WhatsappAction/Whatsappbutton'
import Review from './CatalogueTemplate/Review'

function NykaaCatalogue() {
  return (
    <>
<Navbar/>
       <div className='container'>
          <ArticleHeader serviceName="Nykaa Catalogue"/>
           <div className='row article-container'>
           <h2 className='text-center'> Nykaa <mark>Product Listing</mark> and Cataloging</h2>
           <MainContent service="nykaa" />

             <div className='article text-justify'>
             Technovita Solution is one if the biggest Nykaa patner network in India. Nykaa marketplace is getting more cut-throat by the day. With increased competition, it’s getting harder to take your slice of the pie. Nykaa Product Listing and cataloguing play a vital role in the e-commerce business. Shoppers make decisions for buying a product based on the listing and description displayed.The Good news is Technovita Solution is here to help ! Ride the wave for the best E-Commerce Service provider in Delhi-NCR.So, with competition getting hotter every day, how can you increase your sales on Nykaa, and take your business to the next level?The good news is, we Technovita Solution is here to help . And despite competition going up, the share of sales available to sellers on the Nykaa marketplace is getting bigger and bigger.
             </div>
             <div className='article'>&nbsp;</div>
             <div className="article text-justify">
              <Whatsappbutton />
            </div>
             <div className='article text-justify'>
             What we <strong>Technovita Solution </strong>, will do:
            If you want catalogue service for Nykaa Business Technovita Solution is the best counter. We are the group of experienced and professionals people. Technovita Solution holds the illustration of its customer presence on Nykaa as follows:         
             </div>
             <div className='article'>&nbsp;</div>
             <h3 className='text-center'>How to do a Listing on Nykaa:</h3>
           <img className='article-catalogue-service-img  mx-auto' src='/Images/Article/nykaa/nykaa.png'/>

             <div className='article text-justify'>
              <h5> <strong>1.Catalogue Service for Nykaa India -</strong></h5>
               "We handle the complete catalogue service for Nykaa India (or other country specific Nykaa sites). This includes creating database of photographs of products and linking them with appropriate content written by our expert content writers to convert visitors to buyers while giving our maximum information in the most concise way."
             </div>
             <div className='article'>&nbsp;</div>
             <div className='article text-justify'>
              <h5> <strong>2.Account Creation -</strong></h5>
              We create the account of client at the Preferred marketplace to proceed forward and also take the brand approval <strong>GTIN </strong>to make your product online.        
            </div>
            <div className='article'>&nbsp;</div>
            <div className='article text-justify'>
              <h5> <strong>3.Product Listing -</strong></h5>
Our team do listing of products by upload images and text to Nykaa template in full compliance with nykaa.           
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>4.Promotion -</strong></h5>
Account launch and the promotion of uploaded products is done by us through the process of smo and seo.
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>5.Daily Updation -</strong></h5>
We update and refresh contents on a daily, weekly or monthly basis as may be agreed upon.
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>6.Nykaa Boost Services  -</strong></h5>
We offer premium Nykaa boost services that help drive traffic and improve conversions on your Nykaa product pages. Since this is an open competitive marketplace we keep tabs on prices and help you make the best offers
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>7.Product Portfolios Creation -</strong></h5>
We create customised product portfolios from which products can be launched or (when stocks are exhausted) removed, enabling full, up to the minute control and accurate information for buyers.
         </div>
         <div className='article'>&nbsp;</div>
         <div className="article text-justify">
              <Whatsappbutton />
            </div>
            <Review accServiceName="nykaa"/>

         <div className='article text-justify'>
         <Faq service="nykaa" />

<strong>
     “Technovita Solution” helps in Enriching your Product Catalog and also helps increase your product visibility in the online E-Commerce Business."
</strong>
         </div>
           </div>
       </div>
       <Callbutton/>
    </>
  )
}

export default NykaaCatalogue