import React from 'react'
import ArticleContent from './ArticleContent'
function VoonikCatalogue() {
  return (
    <>
<ArticleContent 
 headerName="Voonik Catalogue" 
 serviceName=" Voonik "
imgUrl="/Images/Article/voonik/voonik.png"
/>
    </>
  )
}

export default  VoonikCatalogue