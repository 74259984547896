import React from 'react'
import AccountTemplate from './AccountTemplate'

function MeeshoAccountManagement() {
  return (
    <>

        <AccountTemplate
        accountManagementService="Meesho Account Management Services" 
        accServiceName="Meesho"
        imgUrl="/Images/AccManagement/meeshoaccount.png"
        imgAlt="meesho Account Management service"
        />
    </>
  )
}

export default MeeshoAccountManagement