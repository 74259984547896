import React from 'react'
import ArticleContent from './ArticleContent'
function JabongCatalogue() {
  return (
    <>
<ArticleContent 
 headerName="Jabong Catalogue" 
 serviceName=" Jabong "
imgUrl="/Images/Article/jabong/jabong.png"
/>
    </>
  )
}

export default JabongCatalogue