import React from 'react';
import '../index.css'
function Header() {
  return <>

<div className='container-lg who-we-are-container'>
<div className="row mb-5 who-we-are-top">
<div className="vl">
<h1><strong>Who we are?</strong> </h1></div>

<div className="our-section-text">
<p>Technovita Solution, is an online-medium where we build, create and nurture your idea in a genre of preferred brands!
We are a team of passionate content writers, Digital marketers, business brains, designers, Catalogue Creation, Account Creation and advertising experts who relentlessly strive to give the best possible results.</p>
</div>
</div>
<div className='row '>
  <div className='col'>

  <div className="vl">
<h1>Our Mission</h1></div>

<div className="our-section-text">
<p>
"To enrich client's business with relevant strategies, provide  valuable inputs".
</p>
</div>


  </div>

  <div className='col'>

<div className="vl">
<h1 >Our Vision</h1></div>

<div className="our-section-text" >
<p>
By deep analysis of the target customers we help seamlessly transform client's businesses.
</p>
</div>
</div>

<div className='col'>

<div className="vl">
<h1 >Core Values </h1></div>

<div className="our-section-text" >
<p>
Continous Improvement,Will to win and Quality Consciousness are our core Values
</p>
</div>
</div>
</div>
</div>


  </>;
}

export default Header;
