import React,{ useRef }  from "react";
import emailjs from '@emailjs/browser';
import './form.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Form() {
  const notify = () => toast("Thanks! We Will Contact You Soon.");
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_umz9mcn','template_g8217uo',form.current,'user_XMd9ExYoK4ZoB8pbmemE1')
      .then((result) => {
          console.log(result.text);
          notify();
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };
  

  return (
    
    <div className="form-container">
      <form ref={form} onSubmit={sendEmail}>
        <input className="form-input"
          type="text"
          placeholder="Enter your Name"
          name="name"
          required
        />
          <input className="form-input"
          
          type="email"
          placeholder="Enter your Email"
          name="email"
required
         
        />
         <input className="form-input"
          
          type="tel"
          placeholder="Enter your Number"
          name="number"

         
        />
       
        <input className="form-button" type="submit" value="Submit" />
      </form>
      <ToastContainer 
      theme="dark"
      position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
      />
    </div>
   
  );
}

export default Form;
