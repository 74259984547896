import React from 'react';
import Form from './Form/Form'

function ContactForm() {
  return <>

<div class="container contact-container">
  <div class="row">
  <h1>Get In touch With Us!</h1>
    <div class="col-4  contact-container-info">
    <h1>Contact Information</h1>
    <div className='details-container'>
    <div className='call'>
     <i class="fas fa-phone-alt call-icon"></i>
     +91 7451073504
    </div>
    <div className='email-address'>
    <i class="fas fa-envelope call-icon"></i>
    info@technovitasolution.com
    </div>
    <div className='address'>
    <i class="fas fa-map-marker-alt call-icon"></i>
    D-41, C Block, Sector 59, Noida
    </div>
      </div>
    
    </div>
    <div class="col-8  contact-container-form" >
      
    <Form />

    </div>
    
  </div>
</div>


  </>;
}

export default ContactForm;
