import React from 'react'
import ArticleContent from './ArticleContent'
function LimeroadCatalogue() {
  return (
    <>
<ArticleContent 
 headerName="Limeroad Catalogue" 
 serviceName=" Limeroad "
imgUrl="/Images/Article/limeroad/limeroad.png"
/>
    </>
  )
}

export default LimeroadCatalogue