import React from "react";

function Faq(props) {
  return (
    <>
      <div className="container mt-5 mb-5">
        <h1 className="text-center" style={{color:'#F2903D'}}>Product listing and Catalog FAQ-</h1>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                what is product listing on {props.service}?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                If you want to sell your product on {props.service} then you
                have upload all the necessary details about your product and add
                images of the product so that a customer can make an decison to
                buy your product.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How to list product on {props.service}
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                It's could be a bit difficult if you don’t have any technical
                knowledge you can read our article how to list proudct on{" "}
                {props.service} and then also you don't understand then we are
                having team of experts who can help you to list proudcts on{" "}
                {props.service} and help you to start your selling journey on{" "}
                {props.service}.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How to change photo of product {props.service}?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                If you want to change photo of a product on your seller account
                on {props.service} then you can edit the product listing of{" "}
                {props.service} and can submit a new request to change if you
                are unable to do that by yourself and want to hire us you can we
                are a team of ecommerce expert who can help you to make your
                seller journey very easy.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How to do a good product listing and make a seo ready catalogue
                on {props.service}?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                if you are new seller on {props.service} then we will highly
                recommend you to make your product listing as good as much it
                can be because this thing only will help you to make your
                products visible infront of millions of people in{" "}
                {props.service} and will make your product standout form other
                products so for best seo ready product listing or cataloging you
                can reach us on technovitasolution.com or contact us through
                call we will help you to make a successful seller on{" "}
                {props.service} and will help you to list your products in best
                possible way on {props.service}.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
