import React from 'react'
import ModalForm from './ModalForm/ModalForm'

function ArticleHeader(props) {
  return (
    <>
         <div className='row services-header'>
               <div className='col services-content '>
                   <h1><span className='service-name'>{props.serviceName}</span></h1>
                   <ModalForm/>
               </div>
               <div className='col'>
                   <img className='header-img' src='https://www.hyperlinkinfosystem.com/assets/uploads/banner/1634019932.png'/>
               </div>
           </div>
    </>
  )
}

export default ArticleHeader