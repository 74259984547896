import React from 'react'
import AccountTemplate from './AccountTemplate'

function ShopeeAccountManagement() {
  return (
    <>
    <AccountTemplate
    accountManagementService="Myntra Account Management Services" 
accServiceName="Myntra"
imgUrl="/Images/AccManagement/shopeeaccount.png"
  imgAlt="myntra Account Management service"
/>
</>
  )
}

export default ShopeeAccountManagement