import React from 'react'
import ArticleContent from './ArticleContent'
import MainContent from './CatalogueTemplate/MainContent'
import Faq from './PageFaq/Faq'
import Whatsappbutton from '../WhatsappAction/Whatsappbutton'
import Review from './CatalogueTemplate/Review'
function AjioCatalogue() {
  return (
    <>
<ArticleContent 
 headerName="Ajio Product Listing and Cataloging" 
 serviceName=" Ajio "
imgUrl="/Images/Article/ajio/ajio.png"
/>
<div className="article text-justify">
              <Whatsappbutton />
            </div>
<MainContent service="Ajio" />
<Review accServiceName="Ajio"/>
<Faq service="Ajio" />



    </>
  )
}

export default AjioCatalogue