const CardData=[
    {  id:1,
       imgSrc:"/Images/amazon.webp",
       title:"Amazon Catalogue Services",
       text:"Technovita Solution is one stop solution for all amazon services which is required to grow your Ecommerce Business. ",
       url:"/services/amazon-catalogue-services"
     },
     {  id:2,
        imgSrc:"/Images/partner/flipkart.png",
        title:"Flipkart Catalogue Services",
        text:"Technovita Solution is one stop solution for all Flipkart services which is required to grow your Ecommerce Business. ",
        url:"/flipkart-catalogue"
 

      },
      {  id:3,
        imgSrc:"/Images/partner/Meesho.png",
        title:"Meesho Catalogue Services",
        text:"Technovita Solution is one stop solution for all Meesho services which is required to grow your Ecommerce Business. ",
        url:"/services/meesho-Catalogue"
 

      },
      {  id:4,
        imgSrc:"/Images/partner/paytm.png",
        title:"Paytm Catalogue Services",
        text:"Technovita Solution is one stop solution for all Paytm services which is required to grow your Ecommerce Business. ",
        url:"/services/paytm-Catalogue"
 

      },
      {  id:5,
        imgSrc:"/Images/partner/ebay.png",
        title:"Ebay Catalogue Services",
        text:"Technovita Solution is one stop solution for all Ebay services which is required to grow your Ecommerce Business. ",
        url:"/services/ebay-Catalogue"
 

      },
      {  id:6,
        imgSrc:"/Images/partner/myntra.jpeg",
        title:"Myntra Catalogue Services",
        text:"Technovita Solution is one stop solution for all Myntra services which is required to grow your Ecommerce Business. ",
        url:"/services/myntra-Catalogue"
 

      },
      {  id:7,
        imgSrc:"/Images/partner/nykaa.png",
        title:"Nykaa Catalogue Services",
        text:"Technovita Solution is one stop solution for all Nykaa services which is required to grow your Ecommerce Business. ",
        url:"/services/nykaa-Catalogue"
 

      },
      {  id:8,
        imgSrc:"/Images/partner/shopee.png",
        title:"Shopee Catalogue Services",
        text:"Technovita Solution is one stop solution for all Shopee services which is required to grow your Ecommerce Business. ",
        url:"/services/shopee-Catalogue"
 

      },
      {  id:9,
        imgSrc:"/Images/partner/ajio.svg",
        title:"Ajio Catalogue Services",
        text:"Technovita Solution is one stop solution for all Ajio services which is required to grow your Ecommerce Business. ",
        url:"/services/ajio-catalogue"
 

      },
      {  id:10,
        imgSrc:"/Images/partner/snapdeal.webp",
        title:"Snapdeal Catalogue Services",
        text:"Technovita Solution is one stop solution for all Snapdeal services which is required to grow your Ecommerce Business. ",
        url:"/services/snapdeal-catalogue"
 

      },
      {  id:11,
        imgSrc:"/Images/partner/etsy.png",
        title:"Etsy Catalogue Services",
        text:"Technovita Solution is one stop solution for all Etsy services which is required to grow your Ecommerce Business. ",
        url:"/services/etsy-catalogue"
 

      },
      {  id:12,
        imgSrc:"/Images/partner/udaan.png",
        title:"Udaan Catalogue Services",
        text:"Technovita Solution is one stop solution for all Udaan services which is required to grow your Ecommerce Business. ",
        url:"/services/udaan-catalogue"
 

      },
      {  id:13,
        imgSrc:"/Images/partner/limeroad.png",
        title:"Limeroad Catalogue Services",
        text:"Technovita Solution is one stop solution for all Limeroad services which is required to grow your Ecommerce Business. ",
        url:"/services/limeroad-catalogue"
 

      },
      {  id:14,
        imgSrc:"/Images/partner/shopclues.png",
        title:"Shopclues Catalogue Services",
        text:"Technovita Solution is one stop solution for all Shopclues services which is required to grow your Ecommerce Business. ",
        url:"/services/shopclues-catalogue"
 

      },
      {  id:15,
        imgSrc:"/Images/partner/jabong.png",
        title:"Jabong Catalogue Services",
        text:"Technovita Solution is one stop solution for all Jabong services which is required to grow your Ecommerce Business. ",
        url:"/services/jabong-catalogue"
 

      },
      {
          id:16,
          imgSrc:"/Images/partner/voonik.webp",
          title:"Voonik Catalogue Services",
          text:"Technovita Solution is one stop solution for all Voonik services which is required to grow your Ecommerce Business. ",
          url:"/services/voonik-catalogue"
   

      }
]
 



export default CardData