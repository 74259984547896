import React, {useState,useEffect} from 'react'
import './Slider.css'
import dataSlider from './dataSlider'

export default function Slider() {

    const [slideIndex, setSlideIndex] = useState(1)


    useEffect(() => {
        const lastIndex = dataSlider.length 
          if (slideIndex < 0) {
            setSlideIndex(lastIndex)
            }
          if (slideIndex >lastIndex){
            setSlideIndex(1)
            }
         }, [slideIndex, dataSlider])
      
      useEffect(() => {
        let slider = setInterval(() => {
          setSlideIndex(slideIndex + 1)
         }, 3000);
          return () => clearInterval(slider)
         }, [slideIndex])


    const moveDot = index => {
        setSlideIndex(index)
    }

    return (<>
        <div className="container-slider">
            {dataSlider.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <img
                        src={`Images/img${index + 1}.jpg`} 
                         alt="technovita solution"/>
                    </div>
                )
            })}
            {/* <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/> */}
                
           
        </div>
        <div className="container-dots">
                {Array.from({length: 4}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                ))}
            </div>
            </>
    )
}



