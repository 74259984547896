import React from "react";
import "./Services.css";
import Navbar from "../Navbar";
import Callbutton from "../Callbutton";
import ArticleHeader from "./ArticleHeader";
import Faq from "./PageFaq/Faq";
import MainContent from "./CatalogueTemplate/MainContent";
import Whatsappbutton from "../WhatsappAction/Whatsappbutton";
function FlipkartCatalogue() {
  return (
    <>
      <Navbar />
      <div className="container">
        <article>
          <ArticleHeader serviceName="Flipkart Product Listing and Catalog " />
          <div className="row article-container">
            <h2 className="text-center">
              Flipkart <mark>Product Listing</mark> and Cataloging
            </h2>
            <MainContent service="flipkart" />
            <div className="article text-justify">
              Flipkart marketplace is getting more cut-throat by the day. With
              increased competition, it’s getting harder to take your slice of
              the pie. Flipkart Product Listing and cataloguing play a vital
              role in the e-commerce business. Shoppers make decisions for
              buying a product based on the listing and description displayed.
              The Good news is <strong>Technovita Solution</strong> is here to
              help ! Ride the wave for the best E-Commerce Service provider in
              Delhi-NCR.
            </div>
            <div className="article">&nbsp;</div>
            <div className="article text-justify">
              So, with competition getting hotter every day, how can you
              increase your sales on Flipkart, and take your business to the
              next level?The good news is, we Technovita Solution is here to
              help . And despite competition going up, the share of sales
              available to sellers on the Flipkart marketplace is getting bigger
              and bigger
            </div>
            <div className="article">&nbsp;</div>
            <div className="article text-justify">
              <Whatsappbutton />
            </div>
            <img
              className="article-catalogue-service-img  mx-auto mt-5"
              src="/Images/Article/flipkart/flipkartDashboard.png"
              alt="flipkart product listing "
              title="flipkart product listing"
            />

            <h3 className="text-center mt-5">
              How to create product catalog : Steps to create a Single Listing{" "}
            </h3>

            <div className="article text-justify">
              <h5>
                <strong>1.Select a vertical  for your product: -</strong>
              </h5>
              Products are segregated based on categories and it is important to
              select the right category and vertical for your listings.Scroll
              through a list of options in the category section once you click
              on ‘Add New Listing’ under the ‘My Listings’ tab.You can also use
              the search bar to find the correct product category.
            </div>
            <div className="article">&nbsp;</div>
            <img
              className="article-catalogue-service-img  mx-auto"
              src="/Images/Article/flipkart/flipkartCategory.png"
              alt="flipkart product listing category selection"
              title="flipkart product listing and catalog"
            />

            <div className="article">&nbsp;</div>
            <div className="article text-justify">
               {" "}
              <h5>
                {" "}
                <strong>2.Get brand approval :-</strong>
              </h5>
              Next, enter brand information for the product. If you get an
              approval message, you can continue with the next step. If not,
              enter the required details and apply for brand approval.You can
              track your approvals by clicking on ‘Track your approval’ under
              ‘My Listings”.{" "}
            </div>
            <div className="article">&nbsp;</div>
            <div className="article text-justify">
               {" "}
              <h5>
                {" "}
                <strong>3.Enter product specifications -</strong>
              </h5>
              Product specifications help customers decide whether they want to
              buy the product from you or another seller, so give as many
              details as possible. Enter relevant details such as product
              description, images, additional information and create a variant,
              if it is applicable. Images of the product you are selling can
              also help a customer choose your product over another seller’s.
              So, ensure that each listing has the appropriate images.
            </div>
            <div className="article">&nbsp;</div>
            <img
              className="article-catalogue-service-img  mx-auto"
              src="/Images/Article/flipkart/productDetails.png"
              alt="flipkart product listing category selection"
              title="flipkart product listing and catalog"
            />

            <div className="article">&nbsp;</div>
            <div className="article text-justify">
              <Whatsappbutton />
            </div>
            <h3 className="text-center">Steps to create a Bulk Listing: :</h3>
            <div className="article text-justify">
               {" "}
              <h5>
                {" "}
                <strong>1.Sign into the Seller Portal :-</strong>
              </h5>
              With your username and password, sign into the Flipkart Seller
              Portal. Click on the ‘Listings’ tab and select ‘My Listings’.
              Click on ‘Add New Listings’ and then on ‘Add Bulk Listing’ on the
              top, right corner of your screen.{" "}
            </div>
            <div className="article">&nbsp;</div>
            <div className="article text-justify">
               {" "}
              <h5>
                {" "}
                <strong>2.Select the category : -</strong>
              </h5>
              Once the page refreshes, select the product category and the
              sub-category. Click on ‘Download Template’.{" "}
            </div>
            <div className="article">&nbsp;</div>
            <div className="article text-justify">
               {" "}
              <h5>
                {" "}
                <strong>3.Make changes to the template:-</strong>
              </h5>
              Open the downloaded Excel template. The first sheet is the Summary
              Sheet which explains how this template works, as per the category
              you chose. Navigate to the ‘Index’ sheet and click on the
              sub-category of your choice. For example, if your category is
              ‘Clothing’, you can click on sub-category ‘Blazers’. This will
              take you to the relevant sheet in the template. Update information
              starting from column G. Enter the SKU ID, brand, style code and
              size based on the values that are acceptable. If you can use
              information from the Index sheet for a specific column, the
              template will give you a link. You must copy and paste this
              information by clicking on the link to ensure that you  don’t have
              to make changes later on. Provide details for all column heads
              that are coloured blue as these are mandatory. Column heads that
              are coloured in green are optional, but it is good to offer this
              information to customers.{" "}
            </div>
            <div className="article">&nbsp;</div>
            <div className="article text-justify">
               {" "}
              <h5>
                {" "}
                <strong>4.Add image URLs to the template: -</strong>
              </h5>
              Go back to ‘Add New Listings’, click on ‘Add Bulk Listing’ and
              then on ‘Image Uploader’. Choose the category and sub-category for
              your product. Enter the SKU ID of the product. Click on ‘Choose
              File’ and select the image from where it is stored on your
              computer. Click on ‘Upload Image’ and copy the URL that is
              displayed on the screen. Paste it in the relevant column in the
              template: Main Image URL, Other Image URL 1 or Other Image URL 2.
            </div>
            <div className="article">&nbsp;</div>
            <div className="article text-justify">
               {" "}
              <h5>
                {" "}
                <strong>5.Submit for Quality Check:-</strong>
              </h5>
              Once you click on ‘Add Bulk Listing’, click on ‘Upload Catalog’.
              Select the correct template file and upload it.
            </div>
            <div className="article">&nbsp;</div>

            <Faq service="flipkart" />
            <div className="article text-justify">
              <strong>
                “Technovita Solution” helps in Enriching your Product Catalog
                and also helps increase your product visibility in the online
                E-Commerce Business."
              </strong>
            </div>
          </div>
        </article>
      </div>
      <Callbutton />
    </>
  );
}

export default FlipkartCatalogue;
