import React,{useState,useEffect,useCallback} from 'react'
import './Testimonial.css'
import testimonials from './TestimonialData'
function Testimonial() {

   
    const firstreview= "Technovita Solution is incredible on time Product uploading and reporting all the daily tasks made by the team via e-mails very good communication with appointed manager, proper resolution of every cases and timely resolve for any problem. They help in increasing sales and  managing the health and performance of our account we are really happy that now our account is completely stable because of them. We will use their services for future too & will be highly recommended to other sellers also."
    const firstname="Neelam Enterprises"
    const [newName, setnewName] = useState(firstreview);
    const [client,setClient]=useState(firstname)

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * testimonials.length);
        setnewName(testimonials[index].text);
        setClient(testimonials[index].name)
    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 10000);
        return () => clearInterval(intervalID);
    }, [shuffle])
  return (
    <>
  <h1 className='text-center' style={{color:"#425066" ,fontSize:"44px"}}><span style={{color:"#EF6937"}}>Seller</span> Success Stories</h1>
    <div class="testimonials-container">
	<div class="progress-bar"></div>
	<div class="fa fa-quote-right fa-quote"></div>
	<div class="fa fa-quote-left fa-quote"></div>
	<p class="testimonial">{newName}</p>
	<div class="centered-items">
		{/* <img class="logo" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&s=707b9c33066bf8808c934c8ab394dff6" alt="logo" /> */}
		<div class="user-details">
			<h4 class="username">~ {client} ~</h4>
		</div>
	</div>
</div>

    </>
  )
}

export default Testimonial