import React from 'react'
import './Otherservices.css'
function Otherservices() {
  return (
    <>
<section class="other-services">

<div class="other-services-container">

    <div class="other-services-title">
        <div class="other-service-title">
        
               <h2><span> Our </span>  Other Services</h2>
        </div>
    </div>

    <div class="other-services-box">
       
       <div class="other-box">
              <div class="ser-box">
                 <div class="icon">
                     <img src="/Images/otherservices/setting.png"/>
                 </div>
                 <h4>Web Development</h4>
                 <p>We Technovita solutions provide custom web solutions services to build your  web presence.</p>
              </div>
       </div>

       <div class="other-box">
            <div class="ser-box">
               <div class="icon">
                   <img src="/Images/otherservices/speed.png"/>
               </div>
               <h4>SEO</h4>
               <p>We Technovita solutions provide SEO Services to make your content visible  on intenet.</p>
            </div>
       </div>

      <div class="other-box">
        <div class="ser-box">
           <div class="icon">
               <img src="/Images/otherservices/protect.png"/>
           </div>
           <h4>Support and Maintenance</h4>
           <p>We Technovita solutions provide services to maintain your website and social media accounts.</p>
        </div>
      </div>

      <div class="other-box">
        <div class="ser-box">
           <div class="icon">
               <img src="/Images/otherservices/backup.png"/>
           </div>
           <h4>UI/UX Design</h4>
           <p>We Technovita solutions provide eye-catching  UI/UX Design services for web and app.</p>
        </div>
      </div>

      <div class="other-box">
      <div class="ser-box">
         <div class="icon">
             <img src="/Images/otherservices/freessl.png"/>
         </div>
         <h4> Social Media Marketing</h4>
         <p>We Technovita solutions provide facebook,google,Instagram,Influncer Marketing Services.</p>
      </div>
      </div>

      <div class="other-box">
       <div class="ser-box">
          <div class="icon">
             <img src="/Images/otherservices/database.png"/>
          </div>
         <h4>Hosting</h4>
         <p>We Technovita solutions provide Hosting services as per your business requirement.</p>
       </div>
      </div>


    </div>

</div>
</section> 

    </>
  )
}

export default Otherservices 