import React from 'react'
import AccountTemplate from './AccountTemplate'

function AjioAccountManagement() {
  return (
    <>

        <AccountTemplate
        accountManagementService="Ajio Account Management Services" 
        accServiceName="AJIO"
        imgUrl="/Images/AccManagement/ajioaccount.png"
        imgAlt="ajio Account Management service"
        />
    </>
  )
}

export default AjioAccountManagement