import React from 'react'
import AccountTemplate from './AccountTemplate'

function NykaaAccountManagement() {
  return (
    <>
         <AccountTemplate
              accountManagementService="Nykaa Account Management Services" 
        accServiceName="Nykaa"
        imgUrl="/Images/AccManagement/nykaaaccount.png"
        imgAlt="nykaa Account Management service"
        />
    </>
  )
}

export default NykaaAccountManagement