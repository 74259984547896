import React from 'react'
import ArticleContent from './ArticleContent'
function EtsyCatalogue() {
  return (
    <>
<ArticleContent 
 headerName="Etsy Catalogue" 
 serviceName=" Etsy "
imgUrl="/Images/Article/etsy/etsy.png"
/>
    </>
  )
}

export default EtsyCatalogue