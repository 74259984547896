import React from 'react'
import './Services.css'
import Navbar from '../Navbar'
import Callbutton from '../Callbutton'
import ArticleHeader from './ArticleHeader'
import MainContent from './CatalogueTemplate/MainContent'
import Faq from './PageFaq/Faq'
import Whatsappbutton from '../WhatsappAction/Whatsappbutton'
import Review from './CatalogueTemplate/Review'

function MeeshoCatalogue() {
  return (
    <>
<Navbar/>
       <div className='container'>
          <ArticleHeader serviceName="Meesho Catalogue"/>
           <div className='row article-container'>
           <h2 className='text-center'> Meesho <mark>Product Listing</mark> and Cataloging</h2>
           <MainContent service="meesho" />
             <div className='article text-justify'> 
             <strong>Technovita Solution</strong>  is here to help you fly in the world of online retail with its managed catalogue service forMeesho, one of the biggest online marketplaces in India. As the leading provider of catalogue service in India we have the expertise, experience and knowledge of how Meesho works and a deep understanding of what online buyers require of online product sellers.
             </div>
             <div className='article'>&nbsp;</div>
             <div className="article text-justify">
              <Whatsappbutton />
            </div>
             <div className='article text-justify'>
             What we <strong>Technovita Solution </strong>, will do:
             Meesho holds the tremendous promise of a huge boost in your business revenues if you join their platform as a seller. Among other things you would need to have a superb, user friendly and appealingly striking e-catalogue on Meesho. This is where Technovita proves to be your friend. We set you off to a running start with superb online Meesho customized catalogues. Focus on selling while we take care of the presentation and online catalogue management.
Technovita Solution is one of the biggest Partner Service Network  of Meesho.              </div>
             <div className='article'>&nbsp;</div>
             <h3 className='text-center'>How to do a Listing on Meesho:</h3>
           <img className='article-catalogue-service-img  mx-auto' src='/Images/Article/meesho/meesho.png'/>

             <div className='article text-justify'>
              <h5> <strong>1.Catalogue Service for Meesho India -</strong></h5>
               "We handle the complete catalogue service for Meesho India (or other country specific Meesho sites). This includes creating database of photographs of products and linking them with appropriate content written by our expert content writers to convert visitors to buyers while giving our maximum information in the most concise way."
             </div>
             <div className='article'>&nbsp;</div>
             <img className='article-catalogue-service-img  mx-auto' src='/Images/Article/meesho/meeshoDashboard.png'/>
             <div className='article'>&nbsp;</div>

             <div className='article text-justify'>
              <h5> <strong>2.Account Creation -</strong></h5>
Create account and company profile with the assigned information and project a positive image. After that the processing of brand approval(GTIN) is done by us.            </div>
            <div className='article'>&nbsp;</div>
            <div className='article text-justify'>
              <h5> <strong>3.Product Listing -</strong></h5>
Compile information about products, upload images and concisely written yet informative and persuasive text to your product pages in compliance with template norms of Meesho. Live your products by taking the brand approval from Meesho.
         </div>
         <div className='article'>&nbsp;</div>
         <img className='article-catalogue-service-img  mx-auto' src='/Images/Article/meesho/meeshoProduct.png'/>
             <div className='article'>&nbsp;</div>

         <div className='article text-justify'>
              <h5> <strong>4.Promotion -</strong></h5>
Account launch and the promotion of uploaded products is done by us through the process of smo and seo.
         </div>
         <div className='article'>&nbsp;</div>
         <img className='article-catalogue-service-img  mx-auto' src='/Images/Article/meesho/meeshoPromotion.png'/>
             <div className='article'>&nbsp;</div>

         <div className='article text-justify'>
              <h5> <strong>5.Daily Updation -</strong></h5>
We update and refresh contents on a daily, weekly or monthly basis as may be agreed upon.
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>6.Meesho Boost Services  -</strong></h5>
We offer premium Meesho boost services that help drive traffic and improve conversions on your Meesho product pages. Since this is an open competitive marketplace we keep tabs on prices and help you make the best offers
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>7.Product Portfolios Creation -</strong></h5>
We create customised product portfolios from which products can be launched or (when stocks are exhausted) removed, enabling full, up to the minute control and accurate information for buyers.
         </div>
         <div className='article'>&nbsp;</div>
         <div className="article text-justify">
              <Whatsappbutton />
            </div>
         <Review accServiceName="meesho"/>
         <div className='article text-justify'>
         <Faq service="meesho" />
<strong>
     “Technovita Solution” helps in Enriching your Product Catalog and also helps increase your product visibility in the online E-Commerce Business."
</strong>
         </div>
           </div>
       </div>
       <Callbutton/>
    </>
  )
}

export default MeeshoCatalogue