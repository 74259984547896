import React from 'react'
import ContentWritingTemplate from './ContentWritingTemplate'
function MyntraContentWriting() {
  return (
    <>
          <ContentWritingTemplate 
        ContentWritingService="Myntra Content Writing Services – High quality & SEO  friendly"
        contentWritingServiceName="Myntra"
        />
    </>
  )
}

export default MyntraContentWriting