import React from 'react'
import Callbutton from '../../Callbutton'
import Navbar from '../../Navbar'
import ModalForm from '../ModalForm/ModalForm'
import '../Services.css'
import Whatsappbutton from '../../WhatsappAction/Whatsappbutton'
import OnboardingFaq from './OnboardingFaq'
import OnboardingReview from './OnboardingReview'

function AjioOnboarding() {
  return (
    <>
     <Navbar/>

<div className='container'>
<div className='row Account-Management-header'>
<img className='account-management-header-img' src='/Images/reconciliationBackground.jpg'/>
<ModalForm/>
    </div>
    <div className='row article-container'>
    <h1 className='text-center account-mg-heading'> AJIO ONBOARDING SERVICE</h1>
      <div className='article text-justify'>
      Selling on ajio can turn out to be a really profitable and easy Business.
       To successfully sell on ajio and to earn good profits one must have to
        list their products on ajio with seo optimization Under the ajio Aggregator
         Business Model, your brand is managed by TECHNOVITA SOLUTION.
           Brand addition is done under  approved aggregator.
          TECHNOVITA SOLUTION will provide you complete solution under this model including a/c management, order processing, shipping and return management etc.
      </div>
      <div className="article text-justify">
            <Whatsappbutton />
          </div>

      <div className='article'>&nbsp;</div>
      <h3 className='text-center'>Ajio Onboarding Process </h3>
      <div className='article text-justify'>
      Below is the step-by-step process of onboarding ajio .</div>
      <div className='article text-justify'>
        <p>Reliance has created a single portal for seller registration, whether they sell on AJIO, Jiomart or Reliance Digital. They treat any seller on their platforms as a “supplier”. So the common steps to register on any of these platforms are as follows. Do note, it helps to treat this registration as a college application or loan application- as AJIO is very careful about who they on-board as a seller. So put your best foot forward. </p>
           <img className='responsiveImage' src='/Images/Onboarding/ajioSellerPortal.png' alt='how to sell products on ajio '/>
 <ul>
      <li>visit at - https://seller.ajio.com/vmsui/auth</li>
      <li>Register yourself on Reliance portal as a supplier</li>
      <li>Fill up the remaining details as required which includes your registered office address.</li>
     <li>Provide documents: Pan card, aadhar card, GST details, current account details, cancelled cheque</li>
     <li>Select your product categories in which you want to sell your products on ajio seller portal</li>
            </ul>
            <p>However, if you are unable to do registration and product listing work by yourself then you can take our help by visiting our website www.technovitasolution.com and you can connect with us to discuss your requirement we have 5+ experience with ecommerce seller and have so many seller success stories. </p>
      </div>
    
      <div className='article text-justify'>
      <h3 className='text-center'> Requirement we need from client who want to onboard on ajio</h3>
      <p>ajio HAS A VERY STRICT PROCESS OF ONBOARDING SO KINDLY SHARE AS MUCH DETAIL AS POSSIBLE</p>
      <ol>
        <li>Name of You Brand.</li>
        <li>Year of Business Establishment.</li>
        <li>What is your product category?</li>
        <li>Website Link, if Any.</li>
        <li>What is the total size of your Portfolio?</li>
        <li>What is the approximate revenue through the online channel per month currently?</li>
        <li>Please share a few links to your top-selling products.</li>
        <li>Are you selling through Offline Channels (Stores), please share the locations.</li>
        <li>Few images of the store (If Available)</li>
        <li>Brand Presentation (If Available)</li>
        <li>Name of Contact Person.</li>
        <li>Your Email (required)</li>
        <li>Contact Number (required)</li>

      </ol>
     </div>

     <div className="article text-justify">
            <Whatsappbutton />
          </div>

          <div className='article text-justify'>
      <h3 className='text-center'>Tips on Increasing Your Chances of Being On-boarded on ajio</h3>
      <p>As mentioned earlier, AJIO is selective about the sellers it on-boards.Hence the following help increase your chances in your application:</p>
      <ol>
        <li><strong>MSME Certificate: </strong>It’s a proof of your company being registered as an MSME with the government create an msme certificate by applying it.</li>
        <li><strong>Logo: </strong> It helps project your company and products as having a distinct brand imagery</li>
        <li><strong>Trademark: </strong> This further strengthens your brand positioning and also is proof of you owning the brand. If you do not have a trademark certificate or it’s in the processing stage; then you can submit a self-declaration; when indicated, during your application.</li>
        <li><strong>Website Address: </strong> A website increases your credibility. It also helps the AJIO team know more about your business and your products. This helps them make a decision.</li>


      </ol>
     </div>



   
  <OnboardingFaq onboardingService="Ajio"/>
  <OnboardingReview accServiceName="Ajio"/>

  <div className='article text-justify'>
      <h3 className='text-center'>Technovita solution Account management services</h3>
      <p>Technovita solution provide account management services for all major ecommerce platforms if you want to take our services then you can connect with us we are having more than 5 years of industry experience , technovita solution can help you to become a successful seller on ajio seller portal  here are our top most account management services- </p>
      <ol>
        <li><strong><a href="https://www.technovitasolution.com/ajio-account-management">Ajio Account Management service</a>
 </strong></li>
        <li><strong><a href="https://www.technovitasolution.com/myntra-account-management">Myntra Account Management service</a> </strong></li>
        <li><strong><a href="https://www.technovitasolution.com/nykaa-account-management">Nykaa Account Management service</a> </strong></li>
        <li><strong><a href="https://www.technovitasolution.com/flipkart-account-management">Flipkart Account Management service</a> </strong></li>
        <li><strong><a href="https://www.technovitasolution.com/amazon-account-management">Amazon Account Management service</a> </strong></li>
        <li><strong><a href="https://www.technovitasolution.com/meesho-account-management">Meesho Account Management service</a> </strong></li>
        <li><strong><a href="https://www.technovitasolution.com/paytm-account-management">Paytm Account Management service</a> </strong></li>
        <li><strong><a href="https://www.technovitasolution.com/ebay-account-management">Ebay Account Management service</a> </strong></li>
        <li><strong><a href="https://www.technovitasolution.com/shopee-account-management">Shopee Account Management service</a> </strong></li>


         </ol>
     </div>

    </div>
</div>
<Callbutton/>
    </>
  )
}

export default AjioOnboarding