import React from 'react'
import AccountTemplate from './AccountTemplate'
function MyntraAccountManagement() {
  return (
    <>
        <AccountTemplate
              accountManagementService="Myntra Account Management Services" 
        accServiceName="Myntra"
        imgUrl="/Images/AccManagement/myntraaccount.png"
        imgAlt="myntra Account Management service"
        />
    </>
  )
}

export default MyntraAccountManagement