import React from 'react'
import './Services.css'
import Navbar from '../Navbar'
import Callbutton from '../Callbutton'
import ArticleHeader from './ArticleHeader'
import MainContent from './CatalogueTemplate/MainContent'
import Faq from './PageFaq/Faq'
import Whatsappbutton from '../WhatsappAction/Whatsappbutton'
import Review from './CatalogueTemplate/Review'
function PaytmCatalogue() {
  return (
    <>
<Navbar/>
       <div className='container'>
          <ArticleHeader serviceName="Paytm Catalogue"/>
           <div className='row article-container'>
           <h2 className='text-center'> Paytm <mark>Product Listing</mark> and Cataloging</h2>
           <MainContent service="paytm" />
             <div className='article text-justify'>
             Technovita Solution is one if the biggest Paytm patner network in India. Paytm marketplace is getting more cut-throat by the day. With increased competition, it’s getting harder to take your slice of the pie.
             </div>
             <div className='article'>&nbsp;</div>
             <div className='article text-justify'>
             Selling on Paytm can turn out to be a really profitable and easy Business. To successfully sell on Paytm, you  must know how to improve your sales on Paytm. In this Article we Technovita Solution will be telling you about  10 ways to massively increase your sales on Paytm. Paytm Product Listing and cataloguing play a vital role in the e-commerce business. Shoppers make decisions for buying a product based on the listing and description displayed.
             </div>
             <div className='article'>&nbsp;</div>
            
             <h3 className='text-center'>How to do a Listing on Paytm:</h3>
           <img className='article-catalogue-service-img  mx-auto' src='/Images/Article/paytm/paytm.png'/>

             <div className='article text-justify'>
              <h5> <strong>1.Catalogue Service for Paytm India -</strong></h5>
               "We handle the complete catalogue service for Paytm India (or other country specific Paytm sites). This includes creating database of photographs of products and linking them with appropriate content written by our expert content writers to convert visitors to buyers while giving our maximum information in the most concise way."
             </div>
             <div className='article'>&nbsp;</div>
             <div className='article text-justify'>
              <h5> <strong>2.Account Creation -</strong></h5>
              We create the account of client at the Preferred marketplace to proceed forward and also take the brand approval <strong>GTIN </strong>to make your product online.        
            </div>
            <div className='article'>&nbsp;</div>
            <div className='article text-justify'>
              <h5> <strong>3.Product Listing -</strong></h5>
Our team do listing of products by upload images and text to Paytm template in full compliance with Paytm.           
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>4.Promotion -</strong></h5>
Account launch and the promotion of uploaded products is done by us through the process of smo and seo.
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>5.Daily Updation -</strong></h5>
We update and refresh contents on a daily, weekly or monthly basis as may be agreed upon.
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>6.Paytm Boost Services  -</strong></h5>
We offer premium Paytm boost services that help drive traffic and improve conversions on your Paytm product pages. Since this is an open competitive marketplace we keep tabs on prices and help you make the best offers
         </div>
         <div className='article'>&nbsp;</div>
         <div className='article text-justify'>
              <h5> <strong>7.Product Portfolios Creation -</strong></h5>
We create customised product portfolios from which products can be launched or (when stocks are exhausted) removed, enabling full, up to the minute control and accurate information for buyers.
         </div>
         <Review accServiceName="paytm"/>
         <div className='article'>&nbsp;</div>
         <div className="article text-justify">
              <Whatsappbutton />
            </div>
         <div className='article text-justify'>
         <Faq service="paytm" />

<strong>
     “Technovita Solution” helps in Enriching your Product Catalog and also helps increase your product visibility in the online E-Commerce Business."
</strong>
         </div>
           </div>
       </div>
       <Callbutton/>
    </>
  )
}

export default PaytmCatalogue