import React from 'react'
import AccountTemplate from './AccountTemplate'

function PaytmAccountManagement() {
  return (
    <>
          <AccountTemplate
              accountManagementService="Paytm Account Management Services" 
        accServiceName="Paytm"
        imgUrl="/Images/AccManagement/paytmaccount.png"
        imgAlt="paytm Account Management service"
        />
    </>
  )
}

export default PaytmAccountManagement