const testimonials = [
    {
        name: 'Neelam Enterprises',
        photo: 'add_photo_url',
        text: "Technovita Solution is incredible on time Product uploading and reporting all the daily tasks made by the team via e-mails very good communication with appointed manager, proper resolution of every cases and timely resolve for any problem. They help in increasing sales and  managing the health and performance of our account we are really happy that now our account is completely stable because of them. We will use their services for future too & will be highly recommended to other sellers also."
    },
    {
        name: 'Needy World',
        photo: 'add_photo_url',
        text: "Thank You Technovita solution for giving a fantastic service, also help us to provide our account manage for all thing. You Gives me a all time support for listing and support for any query regard our account. Thank You, And also you can connect with us and helping to anytime for any query. We Warmly Thankful"
    },
    {
        name: 'Pempolli',
        photo: 'add_photo_url',
        text: "Excellent Work, Very cooperative understanding people. We are very satisfied with the services provided by them.  Always available for us. Have helped in boosting sales and account management,Been to many ,but got results from them."
    },
];
export default testimonials;