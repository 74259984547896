import React from 'react'
import ArticleContent from './ArticleContent'
function ShopcluesCatalogue() {
  return (
    <>
<ArticleContent 
 headerName="Shopclues Catalogue" 
 serviceName=" Shopclues "
imgUrl="/Images/Article/shopclues/shopclues.png"
/>
    </>
  )
}

export default ShopcluesCatalogue