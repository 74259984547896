import React from 'react'
import './Whatsappbutton.css'
function Whatsappbutton() {
  return (
    <div className='whatsapp-action-button-container'>
     <a  href="https://api.whatsapp.com/send?phone=7451073504&amp;text=Hi%20there%21%0D%0AI%20am%20interested%20in%20%20your%20services.%20Please%20Contact%20me" class="black-text" target="_blank">
    📱 Whatsapp Now 
    </a>

    </div>
  )
}

export default Whatsappbutton

