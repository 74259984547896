import React from 'react'
import AccountTemplate from './AccountTemplate'

function FlipkartAccountManagement() {
  return (
    <>

        <AccountTemplate
        accountManagementService="Flipkart Account Management Services" 
        accServiceName="Flipkart"
        imgUrl="/Images/AccManagement/flipkartaccount.png"
        imgAlt="flipkart Account Management service"
        />
    </>
  )
}

export default FlipkartAccountManagement