import React from "react";
import Callbutton from "../../Callbutton";
import Navbar from "../../Navbar";
import Whatsappbutton from "../../WhatsappAction/Whatsappbutton";
import ModalForm from "../ModalForm/ModalForm";
import { Widget } from "@typeform/embed-react";
import "../Services.css";

function AccountTemplate(props) {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row Account-Management-header">
          <img
            className="account-management-header-img"
            src="/Images/accountMangement.jpg"
            loading="lazy"
          />
          <ModalForm />
        </div>
        <div className="row article-container">
          <h1 className="text-center account-mg-heading">
            {" "}
            {props.accountManagementService}
          </h1>
          <div className="article text-justify">
            <strong>
              <a
                style={{ textDecoration: "none" }}
                href="https://technovitasolution.com"
              >
                TechnovitaSolution
              </a>
            </strong>{" "}
            is one stop solution for all {props.accServiceName} Services which
            is required to grow your E-Commerce Business .Technovita Solution is
            one of the best E-Commerce Service provider in India.
          </div>
          <div className="article text-justify">
            <strong>
              <a
                style={{ textDecoration: "none" }}
                href="https://technovitasolution.com"
              >
                TechnovitaSolution
              </a>
            </strong>{" "}
            provides sellers with Account Management Services, which shares
            relevant inputs to help sellers grow their business to the next
            level on the {props.accServiceName} Marketplace
          </div>
          <div className="article text-justify">
            Have you ever thought about just how quickly your business would
            grow if you had easy access to expert advice? Fortunately, just like
            a travel agent helps you plan a fun, exciting holiday, we provide{" "}
            {props.accServiceName} Account Management Services to help you
            manage your business efficiently by giving you advice and assistance
            whenever you need it. Our services will help you to get more
            customers on {props.accServiceName} and help you to achieve your
            sales target.
          </div>
          <div className="article">&nbsp;</div>
          <h2 className="text-center">
            {" "}
            Learn more about {props.accServiceName} Seller Account Management
            Services-
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "900px", marginTop: "20px" }}
              src={props.imgUrl}
              alt={props.imgAlt}
              loading="lazy"
            />
          </div>
          <div className="article text-justify">
              <h3> What is {props.accServiceName} Account Management?</h3>
            <p>
              {props.accServiceName} account management services include
              everything like creating your {props.accServiceName} seller
              account, registering your brand, product listing, order
              management, {props.accServiceName} advertisement management, etc,
              These services are carried out to maintain a healthy seller
              account and scale your business. We are the best seller service
              provider of {props.accServiceName} Seller Account Management
              Services in Delhi NCR.
            </p>
          </div>
          <div className="article text-justify">
              <h3>How We Manage {props.accServiceName} Seller Account?</h3>
            <p>
              {props.accServiceName} Seller Account Management involves many
              Services that are mentioned as follows,
              <ul>
                <li>Setting up an {props.accServiceName} Seller account</li>
                <li>Registering your brand On {props.accServiceName}</li>
                <li>Completing Brand Approval Procedure</li>
                <li>Product listing on {props.accServiceName}</li>
                <li>
                  Optimize your list for the {props.accServiceName} search
                  engine for better visibility.
                </li>
                <li>
                  Inventory management of products on {props.accServiceName}
                </li>
                <li>Order Management</li>
                <li>Advertisement Management on {props.accServiceName}</li>
                <li>Marketing services of products</li>
                <li>Customer Support and a lot more</li>
              </ul>
              We provide all these above-mentioned services to{" "}
              {props.accServiceName} sellers by being responsible for maximizing
              revenue generation and attaining business growth on{" "}
              {props.accServiceName}.
            </p>
          </div>

          <div className="article text-justify">
             {" "}
            <h3>
              {" "}
              How Much Does it Cost for {props.accServiceName} Seller Account
              Management Services?
            </h3>
            <p>
              There are different types of account management services
              available. Based on the service that you require, you can choose
              your model. The charges depend on the type and the number of
              services that you avail of for the {props.accServiceName} seller
              account management services. For example, the{" "}
              {props.accServiceName} advertising account Service will charge
              only for advertising activities.
            </p>
          </div>

          <div className="article text-justify">
             {" "}
            <h3>
              Get {props.accServiceName} Account Management Services with us?
            </h3>
            <p>
              With the number of freelancing sites and agencies available today,
              hiring a perfect {props.accServiceName} Account Management
              services provider could be challenging. But our domain expertise
              ensures you
            </p>
            <ul>
              <li>
                Set your goals of growth on {props.accServiceName} and create a
                plan according to that.
              </li>
              <li>We have vast experience to create seller success stories </li>
              <li>We ensure quality and on-time work commitment.</li>
              <li>We also provide live support on your queries.</li>
            </ul>
          </div>

          <div className="article text-justify">
            <Whatsappbutton />
          </div>

          <div className="article text-justify">
             {" "}
            <h2>
              Our {props.accServiceName} Account Management Services Includes-
            </h2>
          </div>

          <div className="article text-justify">
              <h3>1.{props.accServiceName} seller central account Setup-</h3>
            <p>
              If you are Facing problems setting up your {props.accServiceName}{" "}
              central seller account? Contact our seller account management
              consultants. We will set up your {props.accServiceName} central
              account so that you can focus on your product building and the
              rest of the things we will take care of.
            </p>
          </div>

          <div className="article text-justify">
             {" "}
            <h3>
              2. {props.accServiceName} seller account Category Approval-{" "}
            </h3>
            <p>
              Frustrated by rejecting your application for category approval? we
              use legitimate tricks and hacks, our {props.accServiceName} seller
              management experts will get approval to sell in the restricted
              categories. all you need is to just contact us and for the rest of
              the things, we will take care of.
            </p>
          </div>

          <div className="article text-justify">
             {" "}
            <h3>
              3. Product Listing on {props.accServiceName} seller account -{" "}
            </h3>
            <p>
              Listing your products according to their correct category and
              adding all the necessary and relevant product-specific information
              such as SKU, product ID, content, search terms, and other
              attributes. We also provide bulk listing as well as a listing of
              products that have so many sizes and color variants. So if you are
              looking for a product listing on {props.accServiceName} then our
              expert team can help you to achieve the same get in touch with us
              -
            </p>
          </div>

          <div className="article text-justify">
             {" "}
            <h3>
              4. Sponsored product ads on {props.accServiceName} seller account{" "}
            </h3>
            <p>
              We have a dedicated team of experts who runs ads on diffrent
              platform to increase sales of your products including all the
              ecommerce platforms like {props.accServiceName} so that your
              product can visible to more people and you can increase your sells
              make a call now to increae your sells by 100-200%.
            </p>
          </div>

          <div className="article text-justify">
             {" "}
            <h2 className="text-center">
              <strong>Reviews</strong> ⭐️(4.5/5 based on 50+ Ratings)
            </h2>
          </div>
          <div className="article text-justify">
              <h4>priyanka kumari ⭐️⭐️⭐️⭐️</h4>
            <p>
              Great Service! I take their advertisment of products on{" "}
              {props.accServiceName} services Best thing is they were on time
              provided best services.
            </p>
          </div>
          <div className="article text-justify">
              <h4>Mukesh kumar ⭐️⭐️⭐️⭐️⭐️</h4>
            <p>
              I took their {props.accServiceName} account mangement services ,
              they are very professional in their work . I will highly recommend
              them you to choose.
            </p>
          </div>
          <div className="article text-justify">
              <h4>harshit patel ⭐️⭐️⭐️⭐️</h4>
            <p>
              {" "}
              I am a seller on {props.accServiceName} intially my sells was not
              that much but after taking their account mangement services my
              sales increase by 400%(4x) they added good keywords on my{" "}
              {props.accServiceName} seller account regarding my products Thanks
              Technovia solution.
            </p>
          </div>
          <div className="article text-justify">
              <h4>komal gupta ⭐️⭐️⭐️⭐️⭐️</h4>
            <p>
              <a
                style={{ textDecoration: "none" }}
                href="https://technovitasolution.com"
              >
                TechnovitaSolution
              </a>{" "}
              completed the entire work in all of 2-3 days. Extremely
              professional and helpful. Highly Recommended.
            </p>
          </div>
          <div className="article text-justify">
              <h4>Vishal Choudhary ⭐️⭐️⭐️⭐️⭐️</h4>
            <p>
              very patiently worked with us and gave the final product which we
              were very much happy with.
            </p>
          </div>
          <div className="article text-justify">
              <h4>Shakib ⭐️⭐️⭐️⭐️</h4>
            <p>
              Recommend{" "}
              <a
                style={{ textDecoration: "none" }}
                href="https://technovitasolution.com"
              >
                TechnovitaSolution
              </a>{" "}
              to anyone for {props.accServiceName} listing as I received the
              order as soon as he completed my job.
            </p>
          </div>

          <div className="article text-justify">
             
            <h2 className="text-center">
              Book {props.accServiceName} Account Management Services
            </h2>
             
            <Widget
              id="WIh3G316"
              style={{ width: "100%", height: "500px" }}
              className="my-form"
            />
          </div>

          <div className="article text-justify">
             
            <h2 className="text-center">
              Read 📚 our other Articles to know more about online selling-
            </h2>
          </div>
          <div className="article text-justify">
            <ul>
              <li>
                <h4>
                  <a
                    style={{ textDecoration: "none" }}
                    href="http://blog.technovitasolution.com/how-to-register-as-seller-on-ajio/"
                  >
                    How to register on ajio as a seller?
                  </a>{" "}
                </h4>
              </li>
              <li>
                <h4>
                  <a
                    style={{ textDecoration: "none" }}
                    href="http://blog.technovitasolution.com/how-to-create-a-seller-account-on-flipkart/"
                  >
                    How to create a seller account on flipkart
                  </a>{" "}
                </h4>
              </li>
              <li>
                <h4>
                  <a
                    style={{ textDecoration: "none" }}
                    href="http://blog.technovitasolution.com/paytm-sales-boost-services/"
                  >
                    How to increase sales on paytm seller account?
                  </a>{" "}
                </h4>
              </li>
              <li>
                <h4>
                  <a
                    style={{ textDecoration: "none" }}
                    href="http://blog.technovitasolution.com/amazon-product-cataloging-services/"
                  >
                    How to list product on amazon?
                  </a>{" "}
                </h4>
              </li>
              <li>
                <h4>
                  <a
                    style={{ textDecoration: "none" }}
                    href="http://blog.technovitasolution.com/steps-to-become-a-seller-on-amazon/"
                  >
                    How to become a seller on amazon?
                  </a>{" "}
                </h4>
              </li>
              <li>
                <h4>
                  <a
                    style={{ textDecoration: "none" }}
                    href="http://blog.technovitasolution.com/how-to-increase-sales-on-amazon/"
                  >
                    How to increase sales on amazon?
                  </a>{" "}
                </h4>
              </li>
            </ul>
          </div>

          <div className="article text-justify">
             <h2 className="text-center">🙏 Thanks! for Reading.</h2>
            <br />
          </div>
        </div>
      </div>

      <Callbutton />
    </>
  );
}

export default AccountTemplate;
