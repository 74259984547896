import React from 'react'
import ContentWritingTemplate from './ContentWritingTemplate'

function FlipkartContentWriting() {
  return (
    <>
        <ContentWritingTemplate 
        ContentWritingService="Flipkart Content Writing Services – High quality & SEO  friendly"
        contentWritingServiceName="Flipkart"
        />
    </>
  )
}

export default FlipkartContentWriting