import React from 'react'
import Callbutton from '../../Callbutton'
import Navbar from '../../Navbar'
import ModalForm from '../ModalForm/ModalForm'
import '../Services.css'
import Whatsappbutton from '../../WhatsappAction/Whatsappbutton'
function MyntraOnboarding() {
  return (
    <>
    <Navbar/>

<div className='container'>
<div className='row Account-Management-header'>
<img className='account-management-header-img' src='/Images/reconciliationBackground.jpg'/>
<ModalForm/>
    </div>
    <div className='row article-container'>
    <h1 className='text-center account-mg-heading'> MYNTRA ONBOARDING SERVICE</h1>
      <div className='article text-justify'>
      Selling on Myntra can turn out to be a really profitable and easy Business.
       To successfully sell on Myntra and to earn good profits one must have to
        list their products on myntra with seo optimization Under the Myntra Aggregator
         Business Model, your brand is managed by TECHNOVITA SOLUTION.
           Brand addition is done under  approved aggregator.
          TECHNOVITA SOLUTION will provide you complete solution under this model including a/c management, order processing, shipping and return management etc.
      </div>
     

      <div className='article'>&nbsp;</div>
      <h3 className='text-center'>Myntra Onboarding Process </h3>
      <div className='article text-justify'>
      Below is the step-by-step process of onboarding Myntra .</div>
      <div className='article text-justify'>
            <ul>
      <li>Create a presentation showcasing your business product catalog.</li>
      <li>Then, submit the presentation to Myntra’s category managers. (Reaching  right product manager is the key)</li>
      <li>Moving ahead, share details of your business with the manager after a successful presentation.</li>
      <li>Afterward, you will have to provide Myntra with product samples for a quality check. (You may have to send the samples to Myntra’s Gurugram or Bangalore office.)</li>
      <li>Once the product samples pass the quality check Myntra.com will fix a meeting with the business owner.</li>
      <li>The signing of a contract will happen if they approve of your brand.</li>
      <li>You will then get access to the portal.</li>
      <li>The next step is listing of all your products. You will have to follow Myntra’s photography guidelines and get the shoot done by Myntra approved Studios and update the product inventory.</li>
      <li>Myntra.com will place 2-3 dummy orders and dry run the order fulfillment process. This step ensures the seller’s (the business in consideration/you) commitment towards delivery which includes, product quality, packing, and delivery time.</li>
      <li>After a successful dry run, your business is onboard to Myntra.com.</li>
            </ul>
            <p>However, if the business fails to abide by the guidelines provided by Myntra.com, the platform can not only de-list its products but also the brand itself. </p>
      </div>
    
      <div className='article text-justify'>
      <h3 className='text-center'> Requirement we need from client who want to onboard on myntra</h3>
      <p>MYNTRA HAS A VERY STRICT PROCESS OF ONBOARDING SO KINDLY SHARE AS MUCH DETAIL AS POSSIBLE</p>
      <ol>
        <li>Name of You Brand.</li>
        <li>Year of Business Establishment.</li>
        <li>What is your product category?</li>
        <li>Website Link, if Any.</li>
        <li>What is the total size of your Portfolio?</li>
        <li>What is the approximate revenue through the online channel per month currently?</li>
        <li>Please share a few links to your top-selling products.</li>
        <li>Are you selling through Offline Channels (Stores), please share the locations.</li>
        <li>Few images of the store (If Available)</li>
        <li>Brand Presentation (If Available)</li>
        <li>Name of Contact Person.</li>
        <li>Your Email (required)</li>
        <li>Contact Number (required)</li>

      </ol>
     </div>

     <div className="article text-justify">
            <Whatsappbutton />
          </div>

     <div className='article text-justify'>
      <h3 className='text-center'> How technovita solution can help you to onboard on myntra</h3>
      <p>If you want catalogue service for Myntra Business Technovita Solution is the best counter. We are the group of experienced and professionals people. Technovita Solution holds the illustration of its customer presence on Myntra as follows:</p>
      <ul>
        <li>Create & Share Company profile, Storefront link, VRF and Sample catalog initially for approval</li>
        <li>After Image QC approval, 5 products required for physical Quality Check for Myntra with all packaging, tags and labels.</li>
        <li>Once we get the final QC approval we will start the account setup process.</li>
        <li>OPL — One Paper Lane (Documents uploading)</li>
        <li>CTA — Commercial Term Agreement (commissions, incentives, penalties etc)</li>
        <li>PPL — Partner Portal Link (First look of the account on Myntra Uni-commerce Set Up (For Inventory Uploading, New listing)</li>
    
      </ul>
     </div>
  
    </div>
</div>
<Callbutton/>
    </>
  )
}

export default MyntraOnboarding