import React from 'react'
import Callbutton from '../../Callbutton'
import Navbar from '../../Navbar'
import ModalForm from '../ModalForm/ModalForm'
import '../Services.css'

function ContentWritingTemplate(props) {
  return (
    <>
<Navbar/>

       <div className='container'>
       <div className='row Account-Management-header'>
       <img className='account-management-header-img' src='/Images/contentwriting2.jpg'/>
<ModalForm/>
           </div>
           <div className='row article-container'>
           <h1 className='text-center account-mg-heading'> {props.ContentWritingService}</h1>
             <div className='article text-justify'>
             Technovita Solution is a marketing communications {props.contentWritingServiceName} Service Provider Network committed to creating compelling marketing content and strategies.
Being a content Writing Agency, we are Always Passionate, Keen ,Curious & Most relentless…        
     </div>
            
             <div className='article'>&nbsp;</div>
             <div className='article text-justify'>
              <h5> <strong>1.Content Writing Services :</strong></h5>
                <p>
                 Our content writing services are ideal for website content writing. Also we do effective and persuasive sales literature. We specialize in informative blog posts and engaging articles.
                </p>
             </div>
            
             <div className='article text-justify'>
              <h5> <strong>2.Branding :</strong></h5>
                <p>
                iContent's team of expert designers work creatively alongside providing content writing services to bring ideas to life.                </p>
             </div>
            <div className='article text-justify'>
              <h5> <strong>3.Digital Marketing Services :</strong></h5>
                <p>
                We help you with Insightful analysis and smart integrated planning. We act upon strategies to arrive at business solutions and higher ranking.
                </p>
             </div>
    
         <div className='article text-justify'>
         <h3 className='text-center'> Ultimate and Enriching Content Writing Services
 </h3>

 We provide Content Writing services and Digital Marketing services. We design campaigns that forge a blend of compelling visual language, sensible messaging, and relevant content. It isn’t a rocket science, but an emotional connect with brands. Attract, impress and convert clients with our premium content writing services. We write compelling web content, blogs, articles, product descriptions in the shortest turnaround time. 
We are one of the best content writer company in India providing content services to all industries across the globe. You would be able to avail specialized content writer with us for specific niche and hence the content would be relevant.
Our expertise says that the content cannot be drafted without analyzing the keywords. Therefore, we find a set of keywords relevant to your niche. We analyse the traffic and use keywords in the content to optimise website to make it SEO friendly. Secondly, the client ‘Avatar’ is to be understood and strategies to be planned accordingly. Thirdly, and most importantly, one needs to concentrate on creating content  that creates a hook that grabs attention         
    </div>
 
<div className='article'>&nbsp;</div>
<div className='article text-justify'>
              <h5> <strong>Tips for Effective Content Writing :</strong></h5>
                <p>
                    <ol>
                        <li>Do a thorough topic & Keyword Research</li>
                        <li>Empathy</li>
                        <li>Pay Attention to the size of your Content.</li>
                        <li>Impactful Intros</li>
                        <li>Visualization</li>
                        <li>Don't be Pretentious with your words.</li>
                        <li>Added Value</li>
                        <li>Use the right editing tactics.</li>
                        <li>Using a call to Action (CTA)</li>
                        <li>Hyperlinking</li>
                        <li>Use Google Accordingly.</li>

                    </ol>
                </p>
             </div>
           </div>
       </div>
       
      <Callbutton/>

    </>
  )
}

export default ContentWritingTemplate