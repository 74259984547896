import React from 'react';

function Uppernav() {
  return <>
   
      <div class="container-fluid upper-nav">
  <div class="row">
    <div class="col email" >
    <i class="far fa-envelope icon"></i>
    info@technovitasolution.com
    <i class="fas fa-phone-alt mobile-icon"></i>
    +91 7451073504
    </div>
    
    <div class="col  social-media">
    <div  className="social-icon">
    <a className='whiteColorIcon' href="https://www.facebook.com/Technovita-Solution-103803212237662/"><i class="fab fa-facebook icon"></i></a>
    <a className='whiteColorIcon' href="https://mobile.twitter.com/TechnovitaSolu1"><i class="fab fa-twitter icon"></i></a>
    <a className='whiteColorIcon' href="https://www.instagram.com/technovitasolution/?hl=en"><i class="fab fa-instagram icon"></i></a>
   

    </div>
   
    </div>
  </div>
</div>

  </>;
}

export default Uppernav;
