import React from 'react';
import '../index.css'
function Callbutton() {
  return <>

<div class="call-btn">
    <div class="zoomIn"></div>
    <div class="pulse"></div>
    <div class="tada">
        <a href="tel:+917451073504">+917451073504</a>
    </div>
</div>

  </>;
}

export default Callbutton;
