import React from "react";
import '../Services.css';
function MainContent(props) {
  return (
    <>
      <div className="container article text-justify">
        <p>
          {props.service} Product listing or cataloging is the first step in the
          journey to become a seller on {props.service} ,let's understand what
          does mean of {props.service} prouduct listing so {props.service} means
          to show your product on {props.service} you have to upload your
          products on {props.service} with the product title and description of
          product with with good seo keywords so it will be visible to larger
          audience and when it will reach more people there are higher chances
          that you product selles will be increase so in your successful seller
          journey product listing in a right way is the most important part .
        </p>
        <p>
          So if you are looking for a company who can help you to become a
          succesful seler on {props.service} the you are at the right palace we
          at technovitaSolution provide best {props.service} lsiting services in
          all over india we are having a dedicated team of expert employees who
          will help you to list you product and make it visible to larger no of
          audience
        </p>
      </div>
    </>
  );
}

export default MainContent;
