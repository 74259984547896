const PartnerData=[
{
    id:1,
    imgURL:"/Images/partner/amazon.png",
    altText:"amazon listing service provider",
},
{
    id:2,
    imgURL:"/Images/partner/biba.png",
    altText:"biba listing service provider",
},
{
    id:3,
    imgURL:"/Images/partner/ebay.png",
    altText:"ebay listing service provider in india",
},
{
    id:4,
    imgURL:"/Images/partner/flipkart.png",
    altText:"flipkart listing service provider",
},
{
    id:5,
    imgURL:"/Images/partner/limeroad.png",
    altText:"limeroad listing service provider",
},
{
    id:6,
    imgURL:"/Images/partner/paytm.png",
    altText:"paytm listing service provider",
},
{
    id:7,
    imgURL:"/Images/partner/jabong.png",
    altText:"jabong listing service provider",
},
{
    id:8,
    imgURL:"/Images/partner/shopclues.png",
    altText:"shopclues listing service provider",
},
{
    id:9,
    imgURL:"/Images/partner/snapdeal.png",
    altText:"snapdeal listing service provider",
},
{
    id:10,
    imgURL:"/Images/partner/united-colors.png",
    altText:"united-colors listing service provider",
},
{
    id:11,
    imgURL:"/Images/partner/woodland.png",
    altText:"woodland listing service provider",
},
{
    id:12,
    imgURL:"/Images/partner/worldbank.png",
    altText:"amazon listing service provider in noida",
},
{
    id:13,
    imgURL:"/Images/partner/shopee.png",
    altText:"shopee listing service provider in india"
},
{
    id:14,
    imgURL:"/Images/partner/udaan.png",
    altText:"udaan catalogue service provider"
},
{
    id:15,
    imgURL:"/Images/partner/voonik.webp",
    altText:"voonik catalogue service provider"
},
{
    id:16,
    imgURL:"/Images/partner/etsy.png",
    altText:"etsy catalogue service best in india"
},
{
    id:17,
    imgURL:"/Images/partner/ajio.svg",
    altText:"Ajio catalogue service best in india"
}
]
export default PartnerData;